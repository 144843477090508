//
//
//
//
//
//
//
//

import { getDictionary } from '~/utils/butterUtils'

export default {
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    dictwys () {
      if (this.butterDictionaryWYSIWYG) {
        return getDictionary(this.butterDictionaryWYSIWYG, this.lang, 'w', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionaryWYSIWYG () {
      return this.$store.state.butterDictionaryWYSIWYG
    }
  }
}
